import { State } from 'boardgame.io';
import { GameStateMixin } from '../../common/types';
import { MyGameState, Position } from './game';

let megadottValaszlepesek=new Map<string,string>([
  ["", "S"],
  ["A", "CX"],
  ["B", "A"],
  ["BAC", "D"],
  ["BACDG", "F"],
  ["BACDGFE", "U"],
  ["BACDGFEUN", "S"],
  ["BAD", "V"],
  ["BADVJ", "X"],
  ["BADVJXW", "C"],
  ["BADVJXWCO", "F"],
  ["BADVJXWCOFI", "U"],
  ["BAE", "V"],
  ["BAEVJ", "P"],
  ["BAEVJPS", "G"],
  ["BAF", "V"],
  ["BAFVJ", "P"],
  ["BAFVJPS", "G"],
  ["BAG", "V"],
  ["BAGVJ", "X"],
  ["BAGVJXW", "C"],
  ["BAGVJXWCO", "I"],
  ["BAGVJXWCOIF", "R"],
  ["BAH", "G"],
  ["BAHGD", "P"],
  ["BAHGDPL", "V"],
  ["BAI", "G"],
  ["BAIGD", "P"],
  ["BAIGDPL", "V"],
  ["BAJ", "D"],
  ["BAJDG", "F"],
  ["BAJDGFE", "U"],
  ["BAJDGFEUN", "S"],
  ["BAK", "V"],
  ["BAKVJ", "X"],
  ["BAKVJXW", "C"],
  ["BAKVJXWCO", "I"],
  ["BAKVJXWCOIF", "R"],
  ["BAL", "V"],
  ["BALVJ", "X"],
  ["BALVJXW", "C"],
  ["BALVJXWCO", "I"],
  ["BALVJXWCOIF", "R"],
  ["BAM", "D"],
  ["BAMDG", "F"],
  ["BAMDGFE", "U"],
  ["BAMDGFEUN", "S"],
  ["BAN", "G"],
  ["BANGD", "P"],
  ["BANGDPL", "V"],
  ["BAO", "D"],
  ["BAODG", "F"],
  ["BAODGFE", "U"],
  ["BAODGFEUN", "S"],
  ["BAP", "V"],
  ["BAPVJ", "X"],
  ["BAPVJXW", "C"],
  ["BAPVJXWCO", "I"],
  ["BAPVJXWCOIF", "R"],
  ["BAQ", "D"],
  ["BAQDG", "F"],
  ["BAQDGFE", "U"],
  ["BAQDGFEUN", "S"],
  ["BAR", "D"],
  ["BARDG", "F"],
  ["BARDGFE", "U"],
  ["BARDGFEUN", "S"],
  ["BAS", "V"],
  ["BASVJ", "X"],
  ["BASVJXW", "C"],
  ["BASVJXWCO", "I"],
  ["BASVJXWCOIF", "R"],
  ["BAT", "G"],
  ["BATGD", "P"],
  ["BATGDPL", "V"],
  ["BAU", "G"],
  ["BAUGD", "P"],
  ["BAUGDPL", "V"],
  ["BAV", "D"],
  ["BAVDG", "F"],
  ["BAVDGFE", "U"],
  ["BAVDGFEUN", "S"],
  ["BAW", "D"],
  ["BAWDG", "F"],
  ["BAWDGFE", "U"],
  ["BAWDGFEUN", "S"],
  ["BAX", "D"],
  ["BAXDG", "F"],
  ["BAXDGFE", "U"],
  ["BAXDGFEUN", "S"],
  ["C", "AV"],
  ["D", "FU"],
  ["E", "D"],
  ["EDA", "S"],
  ["EDASK", "U"],
  ["EDASKUT", "F"],
  ["EDASKUTFN", "C"],
  ["EDASKUTFNCI", "X"],
  ["EDB", "S"],
  ["EDBSK", "P"],
  ["EDBSKPV", "G"],
  ["EDC", "S"],
  ["EDCSK", "P"],
  ["EDCSKPV", "G"],
  ["EDF", "A"],
  ["EDFAG", "C"],
  ["EDFAGCB", "X"],
  ["EDFAGCBXO", "V"],
  ["EDG", "S"],
  ["EDGSK", "U"],
  ["EDGSKUT", "F"],
  ["EDGSKUTFN", "I"],
  ["EDGSKUTFNIC", "R"],
  ["EDH", "G"],
  ["EDHGA", "P"],
  ["EDHGAPL", "S"],
  ["EDI", "G"],
  ["EDIGA", "P"],
  ["EDIGAPL", "S"],
  ["EDJ", "S"],
  ["EDJSK", "U"],
  ["EDJSKUT", "F"],
  ["EDJSKUTFN", "I"],
  ["EDJSKUTFNIC", "R"],
  ["EDK", "A"],
  ["EDKAG", "C"],
  ["EDKAGCB", "X"],
  ["EDKAGCBXO", "V"],
  ["EDL", "S"],
  ["EDLSK", "U"],
  ["EDLSKUT", "F"],
  ["EDLSKUTFN", "I"],
  ["EDLSKUTFNIC", "R"],
  ["EDM", "A"],
  ["EDMAG", "C"],
  ["EDMAGCB", "X"],
  ["EDMAGCBXO", "V"],
  ["EDN", "A"],
  ["EDNAG", "C"],
  ["EDNAGCB", "X"],
  ["EDNAGCBXO", "V"],
  ["EDO", "G"],
  ["EDOGA", "P"],
  ["EDOGAPL", "S"],
  ["EDP", "S"],
  ["EDPSK", "U"],
  ["EDPSKUT", "F"],
  ["EDPSKUTFN", "I"],
  ["EDPSKUTFNIC", "R"],
  ["EDQ", "A"],
  ["EDQAG", "C"],
  ["EDQAGCB", "X"],
  ["EDQAGCBXO", "V"],
  ["EDR", "A"],
  ["EDRAG", "C"],
  ["EDRAGCB", "X"],
  ["EDRAGCBXO", "V"],
  ["EDS", "A"],
  ["EDSAG", "C"],
  ["EDSAGCB", "X"],
  ["EDSAGCBXO", "V"],
  ["EDT", "A"],
  ["EDTAG", "C"],
  ["EDTAGCB", "X"],
  ["EDTAGCBXO", "V"],
  ["EDU", "A"],
  ["EDUAG", "C"],
  ["EDUAGCB", "X"],
  ["EDUAGCBXO", "V"],
  ["EDV", "S"],
  ["EDVSK", "U"],
  ["EDVSKUT", "F"],
  ["EDVSKUTFN", "I"],
  ["EDVSKUTFNIC", "R"],
  ["EDW", "G"],
  ["EDWGA", "P"],
  ["EDWGAPL", "S"],
  ["EDX", "G"],
  ["EDXGA", "P"],
  ["EDXGAPL", "S"],
  ["F", "DS"],
  ["G", "IR"],
  ["H", "G"],
  ["HGA", "P"],
  ["HGAPL", "R"],
  ["HGAPLRQ", "I"],
  ["HGAPLRQIM", "C"],
  ["HGAPLRQIMCF", "X"],
  ["HGB", "A"],
  ["HGBAD", "V"],
  ["HGBADVJ", "P"],
  ["HGC", "A"],
  ["HGCAD", "V"],
  ["HGCADVJ", "P"],
  ["HGD", "P"],
  ["HGDPL", "R"],
  ["HGDPLRQ", "I"],
  ["HGDPLRQIM", "F"],
  ["HGDPLRQIMFC", "U"],
  ["HGE", "P"],
  ["HGEPL", "V"],
  ["HGEPLVS", "A"],
  ["HGF", "P"],
  ["HGFPL", "V"],
  ["HGFPLVS", "A"],
  ["HGI", "D"],
  ["HGIDA", "F"],
  ["HGIDAFE", "U"],
  ["HGIDAFEUN", "S"],
  ["HGJ", "P"],
  ["HGJPL", "R"],
  ["HGJPLRQ", "I"],
  ["HGJPLRQIM", "C"],
  ["HGJPLRQIMCF", "X"],
  ["HGK", "P"],
  ["HGKPL", "R"],
  ["HGKPLRQ", "I"],
  ["HGKPLRQIM", "C"],
  ["HGKPLRQIMCF", "X"],
  ["HGL", "D"],
  ["HGLDA", "F"],
  ["HGLDAFE", "U"],
  ["HGLDAFEUN", "S"],
  ["HGM", "D"],
  ["HGMDA", "F"],
  ["HGMDAFE", "U"],
  ["HGMDAFEUN", "S"],
  ["HGN", "A"],
  ["HGNAD", "V"],
  ["HGNADVJ", "P"],
  ["HGO", "D"],
  ["HGODA", "F"],
  ["HGODAFE", "U"],
  ["HGODAFEUN", "S"],
  ["HGP", "D"],
  ["HGPDA", "F"],
  ["HGPDAFE", "U"],
  ["HGPDAFEUN", "S"],
  ["HGQ", "D"],
  ["HGQDA", "F"],
  ["HGQDAFE", "U"],
  ["HGQDAFEUN", "S"],
  ["HGR", "D"],
  ["HGRDA", "F"],
  ["HGRDAFE", "U"],
  ["HGRDAFEUN", "S"],
  ["HGS", "P"],
  ["HGSPL", "R"],
  ["HGSPLRQ", "I"],
  ["HGSPLRQIM", "C"],
  ["HGSPLRQIMCF", "X"],
  ["HGT", "A"],
  ["HGTAD", "V"],
  ["HGTADVJ", "P"],
  ["HGU", "A"],
  ["HGUAD", "V"],
  ["HGUADVJ", "P"],
  ["HGV", "P"],
  ["HGVPL", "R"],
  ["HGVPLRQ", "I"],
  ["HGVPLRQIM", "C"],
  ["HGVPLRQIMCF", "X"],
  ["HGW", "D"],
  ["HGWDA", "F"],
  ["HGWDAFE", "U"],
  ["HGWDAFEUN", "S"],
  ["HGX", "D"],
  ["HGXDA", "F"],
  ["HGXDAFE", "U"],
  ["HGXDAFEUN", "S"],
  ["I", "GP"],
  ["J", "V"],
  ["JVA", "P"],
  ["JVAPS", "G"],
  ["JVAPSGL", "I"],
  ["JVAPSGLIH", "R"],
  ["JVB", "P"],
  ["JVBPS", "G"],
  ["JVBPSGL", "I"],
  ["JVBPSGLIH", "R"],
  ["JVC", "P"],
  ["JVCPS", "G"],
  ["JVCPSGL", "I"],
  ["JVCPSGLIH", "R"],
  ["JVD", "S"],
  ["JVDSP", "U"],
  ["JVDSPUT", "X"],
  ["JVE", "P"],
  ["JVEPS", "G"],
  ["JVEPSGL", "I"],
  ["JVEPSGLIH", "R"],
  ["JVF", "P"],
  ["JVFPS", "G"],
  ["JVFPSGL", "I"],
  ["JVFPSGLIH", "R"],
  ["JVG", "X"],
  ["JVGXW", "U"],
  ["JVGXWUR", "S"],
  ["JVH", "S"],
  ["JVHSP", "U"],
  ["JVHSPUT", "X"],
  ["JVI", "S"],
  ["JVISP", "U"],
  ["JVISPUT", "X"],
  ["JVK", "S"],
  ["JVKSP", "U"],
  ["JVKSPUT", "X"],
  ["JVL", "X"],
  ["JVLXW", "U"],
  ["JVLXWUR", "S"],
  ["JVM", "S"],
  ["JVMSP", "U"],
  ["JVMSPUT", "X"],
  ["JVN", "P"],
  ["JVNPS", "G"],
  ["JVNPSGL", "I"],
  ["JVNPSGLIH", "R"],
  ["JVO", "P"],
  ["JVOPS", "G"],
  ["JVOPSGL", "I"],
  ["JVOPSGLIH", "R"],
  ["JVP", "X"],
  ["JVPXW", "C"],
  ["JVPXWCO", "A"],
  ["JVPXWCOAB", "G"],
  ["JVPXWCOABGD", "I"],
  ["JVQ", "X"],
  ["JVQXW", "C"],
  ["JVQXWCO", "A"],
  ["JVQXWCOAB", "D"],
  ["JVQXWCOABDG", "F"],
  ["JVR", "X"],
  ["JVRXW", "C"],
  ["JVRXWCO", "A"],
  ["JVRXWCOAB", "D"],
  ["JVRXWCOABDG", "F"],
  ["JVS", "X"],
  ["JVSXW", "C"],
  ["JVSXWCO", "A"],
  ["JVSXWCOAB", "D"],
  ["JVSXWCOABDG", "F"],
  ["JVT", "X"],
  ["JVTXW", "C"],
  ["JVTXWCO", "A"],
  ["JVTXWCOAB", "D"],
  ["JVTXWCOABDG", "F"],
  ["JVU", "X"],
  ["JVUXW", "C"],
  ["JVUXWCO", "A"],
  ["JVUXWCOAB", "D"],
  ["JVUXWCOABDG", "F"],
  ["JVW", "P"],
  ["JVWPS", "G"],
  ["JVWPSGL", "I"],
  ["JVWPSGLIH", "R"],
  ["JVX", "P"],
  ["JVXPS", "G"],
  ["JVXPSGL", "I"],
  ["JVXPSGLIH", "R"],
  ["K", "S"],
  ["KSA", "V"],
  ["KSAVP", "X"],
  ["KSAVPXW", "U"],
  ["KSB", "P"],
  ["KSBPV", "G"],
  ["KSBPVGL", "I"],
  ["KSBPVGLIH", "R"],
  ["KSC", "P"],
  ["KSCPV", "G"],
  ["KSCPVGL", "I"],
  ["KSCPVGLIH", "R"],
  ["KSD", "P"],
  ["KSDPV", "G"],
  ["KSDPVGL", "I"],
  ["KSDPVGLIH", "R"],
  ["KSE", "P"],
  ["KSEPV", "G"],
  ["KSEPVGL", "I"],
  ["KSEPVGLIH", "R"],
  ["KSF", "P"],
  ["KSFPV", "G"],
  ["KSFPVGL", "I"],
  ["KSFPVGLIH", "R"],
  ["KSG", "U"],
  ["KSGUT", "X"],
  ["KSGUTXR", "V"],
  ["KSH", "V"],
  ["KSHVP", "X"],
  ["KSHVPXW", "U"],
  ["KSI", "V"],
  ["KSIVP", "X"],
  ["KSIVPXW", "U"],
  ["KSJ", "V"],
  ["KSJVP", "X"],
  ["KSJVPXW", "U"],
  ["KSL", "U"],
  ["KSLUT", "X"],
  ["KSLUTXR", "V"],
  ["KSM", "V"],
  ["KSMVP", "X"],
  ["KSMVPXW", "U"],
  ["KSN", "P"],
  ["KSNPV", "G"],
  ["KSNPVGL", "I"],
  ["KSNPVGLIH", "R"],
  ["KSO", "P"],
  ["KSOPV", "G"],
  ["KSOPVGL", "I"],
  ["KSOPVGLIH", "R"],
  ["KSP", "U"],
  ["KSPUT", "F"],
  ["KSPUTFN", "D"],
  ["KSPUTFNDE", "G"],
  ["KSPUTFNDEGA", "I"],
  ["KSQ", "U"],
  ["KSQUT", "F"],
  ["KSQUTFN", "D"],
  ["KSQUTFNDE", "A"],
  ["KSQUTFNDEAG", "C"],
  ["KSR", "U"],
  ["KSRUT", "F"],
  ["KSRUTFN", "D"],
  ["KSRUTFNDE", "A"],
  ["KSRUTFNDEAG", "C"],
  ["KST", "P"],
  ["KSTPV", "G"],
  ["KSTPVGL", "I"],
  ["KSTPVGLIH", "R"],
  ["KSU", "P"],
  ["KSUPV", "G"],
  ["KSUPVGL", "I"],
  ["KSUPVGLIH", "R"],
  ["KSV", "U"],
  ["KSVUT", "F"],
  ["KSVUTFN", "D"],
  ["KSVUTFNDE", "A"],
  ["KSVUTFNDEAG", "C"],
  ["KSW", "U"],
  ["KSWUT", "F"],
  ["KSWUTFN", "D"],
  ["KSWUTFNDE", "A"],
  ["KSWUTFNDEAG", "C"],
  ["KSX", "U"],
  ["KSXUT", "F"],
  ["KSXUTFN", "D"],
  ["KSXUTFNDE", "A"],
  ["KSXUTFNDEAG", "C"],
  ["L", "P"],
  ["LPA", "R"],
  ["LPARQ", "U"],
  ["LPARQUX", "S"],
  ["LPB", "S"],
  ["LPBSV", "U"],
  ["LPBSVUT", "R"],
  ["LPC", "S"],
  ["LPCSV", "U"],
  ["LPCSVUT", "R"],
  ["LPD", "S"],
  ["LPDSV", "U"],
  ["LPDSVUT", "R"],
  ["LPE", "V"],
  ["LPEVS", "A"],
  ["LPEVSAJ", "C"],
  ["LPEVSAJCB", "X"],
  ["LPF", "V"],
  ["LPFVS", "A"],
  ["LPFVSAJ", "C"],
  ["LPFVSAJCB", "X"],
  ["LPG", "V"],
  ["LPGVS", "A"],
  ["LPGVSAJ", "C"],
  ["LPGVSAJCB", "X"],
  ["LPH", "V"],
  ["LPHVS", "A"],
  ["LPHVSAJ", "C"],
  ["LPHVSAJCB", "X"],
  ["LPI", "V"],
  ["LPIVS", "A"],
  ["LPIVSAJ", "C"],
  ["LPIVSAJCB", "X"],
  ["LPJ", "R"],
  ["LPJRQ", "U"],
  ["LPJRQUX", "S"],
  ["LPK", "S"],
  ["LPKSV", "U"],
  ["LPKSVUT", "R"],
  ["LPM", "V"],
  ["LPMVS", "A"],
  ["LPMVSAJ", "C"],
  ["LPMVSAJCB", "X"],
  ["LPN", "V"],
  ["LPNVS", "A"],
  ["LPNVSAJ", "C"],
  ["LPNVSAJCB", "X"],
  ["LPO", "S"],
  ["LPOSV", "U"],
  ["LPOSVUT", "R"],
  ["LPQ", "V"],
  ["LPQVS", "A"],
  ["LPQVSAJ", "C"],
  ["LPQVSAJCB", "X"],
  ["LPR", "V"],
  ["LPRVS", "A"],
  ["LPRVSAJ", "C"],
  ["LPRVSAJCB", "X"],
  ["LPS", "R"],
  ["LPSRQ", "I"],
  ["LPSRQIM", "G"],
  ["LPSRQIMGH", "D"],
  ["LPSRQIMGHDA", "F"],
  ["LPT", "R"],
  ["LPTRQ", "I"],
  ["LPTRQIM", "G"],
  ["LPTRQIMGH", "D"],
  ["LPTRQIMGHDA", "F"],
  ["LPU", "R"],
  ["LPURQ", "I"],
  ["LPURQIM", "G"],
  ["LPURQIMGH", "D"],
  ["LPURQIMGHDA", "F"],
  ["LPV", "R"],
  ["LPVRQ", "I"],
  ["LPVRQIM", "G"],
  ["LPVRQIMGH", "A"],
  ["LPVRQIMGHAD", "C"],
  ["LPW", "R"],
  ["LPWRQ", "I"],
  ["LPWRQIM", "G"],
  ["LPWRQIMGH", "D"],
  ["LPWRQIMGHDA", "F"],
  ["LPX", "R"],
  ["LPXRQ", "I"],
  ["LPXRQIM", "G"],
  ["LPXRQIMGH", "D"],
  ["LPXRQIMGHDA", "F"],
  ["M", "I"],
  ["MIA", "G"],
  ["MIAGH", "P"],
  ["MIAGHPL", "R"],
  ["MIAGHPLRQ", "U"],
  ["MIAGHPLRQUX", "S"],
  ["MIB", "G"],
  ["MIBGH", "P"],
  ["MIBGHPL", "R"],
  ["MIBGHPLRQ", "U"],
  ["MIBGHPLRQUX", "S"],
  ["MIC", "G"],
  ["MICGH", "P"],
  ["MICGHPL", "R"],
  ["MICGHPLRQ", "X"],
  ["MICGHPLRQXU", "V"],
  ["MID", "G"],
  ["MIDGH", "P"],
  ["MIDGHPL", "R"],
  ["MIDGHPLRQ", "U"],
  ["MIDGHPLRQUX", "S"],
  ["MIE", "G"],
  ["MIEGH", "P"],
  ["MIEGHPL", "R"],
  ["MIEGHPLRQ", "U"],
  ["MIEGHPLRQUX", "S"],
  ["MIF", "G"],
  ["MIFGH", "P"],
  ["MIFGHPL", "R"],
  ["MIFGHPLRQ", "U"],
  ["MIFGHPLRQUX", "S"],
  ["MIG", "C"],
  ["MIGCF", "X"],
  ["MIGCFXO", "V"],
  ["MIGCFXOVW", "A"],
  ["MIH", "C"],
  ["MIHCF", "X"],
  ["MIHCFXO", "V"],
  ["MIHCFXOVW", "A"],
  ["MIJ", "F"],
  ["MIJFC", "D"],
  ["MIJFCDE", "G"],
  ["MIK", "C"],
  ["MIKCF", "X"],
  ["MIKCFXO", "V"],
  ["MIKCFXOVW", "A"],
  ["MIL", "C"],
  ["MILCF", "X"],
  ["MILCFXO", "V"],
  ["MILCFXOVW", "A"],
  ["MIN", "F"],
  ["MINFC", "D"],
  ["MINFCDE", "G"],
  ["MIO", "G"],
  ["MIOGH", "D"],
  ["MIOGHDA", "F"],
  ["MIP", "C"],
  ["MIPCF", "X"],
  ["MIPCFXO", "V"],
  ["MIPCFXOVW", "A"],
  ["MIQ", "C"],
  ["MIQCF", "X"],
  ["MIQCFXO", "V"],
  ["MIQCFXOVW", "A"],
  ["MIR", "C"],
  ["MIRCF", "X"],
  ["MIRCFXO", "V"],
  ["MIRCFXOVW", "A"],
  ["MIS", "C"],
  ["MISCF", "X"],
  ["MISCFXO", "V"],
  ["MISCFXOVW", "A"],
  ["MIT", "C"],
  ["MITCF", "X"],
  ["MITCFXO", "V"],
  ["MITCFXOVW", "A"],
  ["MIU", "F"],
  ["MIUFC", "D"],
  ["MIUFCDE", "G"],
  ["MIV", "F"],
  ["MIVFC", "D"],
  ["MIVFCDE", "G"],
  ["MIW", "F"],
  ["MIWFC", "D"],
  ["MIWFCDE", "G"],
  ["MIX", "G"],
  ["MIXGH", "D"],
  ["MIXGHDA", "F"],
  ["N", "F"],
  ["NFA", "D"],
  ["NFADE", "S"],
  ["NFADESK", "U"],
  ["NFADESKUT", "X"],
  ["NFADESKUTXR", "V"],
  ["NFB", "D"],
  ["NFBDE", "S"],
  ["NFBDESK", "U"],
  ["NFBDESKUT", "X"],
  ["NFBDESKUTXR", "V"],
  ["NFC", "D"],
  ["NFCDE", "S"],
  ["NFCDESK", "U"],
  ["NFCDESKUT", "X"],
  ["NFCDESKUTXR", "V"],
  ["NFD", "I"],
  ["NFDIC", "R"],
  ["NFDICRM", "P"],
  ["NFDICRMPQ", "G"],
  ["NFE", "I"],
  ["NFEIC", "R"],
  ["NFEICRM", "P"],
  ["NFEICRMPQ", "G"],
  ["NFG", "D"],
  ["NFGDE", "S"],
  ["NFGDESK", "U"],
  ["NFGDESKUT", "X"],
  ["NFGDESKUTXR", "V"],
  ["NFH", "D"],
  ["NFHDE", "S"],
  ["NFHDESK", "U"],
  ["NFHDESKUT", "X"],
  ["NFHDESKUTXR", "V"],
  ["NFI", "D"],
  ["NFIDE", "S"],
  ["NFIDESK", "U"],
  ["NFIDESKUT", "R"],
  ["NFIDESKUTRX", "P"],
  ["NFJ", "I"],
  ["NFJIC", "R"],
  ["NFJICRM", "P"],
  ["NFJICRMPQ", "G"],
  ["NFK", "I"],
  ["NFKIC", "R"],
  ["NFKICRM", "P"],
  ["NFKICRMPQ", "G"],
  ["NFL", "C"],
  ["NFLCI", "A"],
  ["NFLCIAB", "D"],
  ["NFM", "D"],
  ["NFMDE", "A"],
  ["NFMDEAG", "C"],
  ["NFO", "C"],
  ["NFOCI", "A"],
  ["NFOCIAB", "D"],
  ["NFP", "C"],
  ["NFPCI", "A"],
  ["NFPCIAB", "D"],
  ["NFQ", "C"],
  ["NFQCI", "A"],
  ["NFQCIAB", "D"],
  ["NFR", "D"],
  ["NFRDE", "A"],
  ["NFRDEAG", "C"],
  ["NFS", "I"],
  ["NFSIC", "R"],
  ["NFSICRM", "P"],
  ["NFSICRMPQ", "G"],
  ["NFT", "I"],
  ["NFTIC", "R"],
  ["NFTICRM", "P"],
  ["NFTICRMPQ", "G"],
  ["NFU", "I"],
  ["NFUIC", "R"],
  ["NFUICRM", "P"],
  ["NFUICRMPQ", "G"],
  ["NFV", "I"],
  ["NFVIC", "R"],
  ["NFVICRM", "P"],
  ["NFVICRMPQ", "G"],
  ["NFW", "I"],
  ["NFWIC", "R"],
  ["NFWICRM", "P"],
  ["NFWICRMPQ", "G"],
  ["NFX", "C"],
  ["NFXCI", "A"],
  ["NFXCIAB", "D"],
  ["O", "C"],
  ["OCA", "I"],
  ["OCAIF", "R"],
  ["OCAIFRM", "P"],
  ["OCAIFRMPQ", "G"],
  ["OCB", "I"],
  ["OCBIF", "R"],
  ["OCBIFRM", "P"],
  ["OCBIFRMPQ", "G"],
  ["OCD", "A"],
  ["OCDAB", "V"],
  ["OCDABVJ", "X"],
  ["OCDABVJXW", "U"],
  ["OCDABVJXWUR", "S"],
  ["OCE", "A"],
  ["OCEAB", "V"],
  ["OCEABVJ", "X"],
  ["OCEABVJXW", "U"],
  ["OCEABVJXWUR", "S"],
  ["OCF", "A"],
  ["OCFAB", "V"],
  ["OCFABVJ", "X"],
  ["OCFABVJXW", "U"],
  ["OCFABVJXWUR", "S"],
  ["OCG", "A"],
  ["OCGAB", "V"],
  ["OCGABVJ", "X"],
  ["OCGABVJXW", "U"],
  ["OCGABVJXWUR", "S"],
  ["OCH", "A"],
  ["OCHAB", "V"],
  ["OCHABVJ", "X"],
  ["OCHABVJXW", "U"],
  ["OCHABVJXWUR", "S"],
  ["OCI", "A"],
  ["OCIAB", "V"],
  ["OCIABVJ", "X"],
  ["OCIABVJXW", "R"],
  ["OCIABVJXWRU", "P"],
  ["OCJ", "I"],
  ["OCJIF", "R"],
  ["OCJIFRM", "P"],
  ["OCJIFRMPQ", "G"],
  ["OCK", "I"],
  ["OCKIF", "R"],
  ["OCKIFRM", "P"],
  ["OCKIFRMPQ", "G"],
  ["OCL", "F"],
  ["OCLFI", "D"],
  ["OCLFIDE", "A"],
  ["OCM", "A"],
  ["OCMAB", "D"],
  ["OCMABDG", "F"],
  ["OCN", "F"],
  ["OCNFI", "D"],
  ["OCNFIDE", "A"],
  ["OCP", "F"],
  ["OCPFI", "D"],
  ["OCPFIDE", "A"],
  ["OCQ", "F"],
  ["OCQFI", "D"],
  ["OCQFIDE", "A"],
  ["OCR", "A"],
  ["OCRAB", "D"],
  ["OCRABDG", "F"],
  ["OCT", "I"],
  ["OCTIF", "R"],
  ["OCTIFRM", "P"],
  ["OCTIFRMPQ", "G"],
  ["OCU", "F"],
  ["OCUFI", "D"],
  ["OCUFIDE", "A"],
  ["OCV", "I"],
  ["OCVIF", "R"],
  ["OCVIFRM", "P"],
  ["OCVIFRMPQ", "G"],
  ["OCW", "I"],
  ["OCWIF", "R"],
  ["OCWIFRM", "P"],
  ["OCWIFRMPQ", "G"],
  ["OCX", "I"],
  ["OCXIF", "R"],
  ["OCXIFRM", "P"],
  ["OCXIFRMPQ", "G"],
  ["OCS", "I"],
  ["OCSIF", "R"],
  ["OCSIFRM", "P"],
  ["OCSIFRMPQ", "G"],
  ["P", "RI"],
  ["Q", "R"],
  ["QRA", "U"],
  ["QRAUX", "F"],
  ["QRAUXFN", "I"],
  ["QRB", "U"],
  ["QRBUX", "F"],
  ["QRBUXFN", "I"],
  ["QRC", "I"],
  ["QRCIM", "G"],
  ["QRCIMGH", "P"],
  ["QRCIMGHPL", "S"],
  ["QRCIMGHPLSV", "D"],
  ["QRD", "X"],
  ["QRDXU", "V"],
  ["QRDXUVW", "A"],
  ["QRDXUVWAJ", "C"],
  ["QRE", "X"],
  ["QREXU", "V"],
  ["QREXUVW", "A"],
  ["QREXUVWAJ", "C"],
  ["QRF", "I"],
  ["QRFIM", "G"],
  ["QRFIMGH", "P"],
  ["QRFIMGHPL", "S"],
  ["QRFIMGHPLSV", "D"],
  ["QRG", "X"],
  ["QRGXU", "V"],
  ["QRGXUVW", "A"],
  ["QRGXUVWAJ", "C"],
  ["QRH", "X"],
  ["QRHXU", "V"],
  ["QRHXUVW", "A"],
  ["QRHXUVWAJ", "C"],
  ["QRI", "X"],
  ["QRIXU", "V"],
  ["QRIXUVW", "A"],
  ["QRIXUVWAJ", "C"],
  ["QRJ", "U"],
  ["QRJUX", "F"],
  ["QRJUXFN", "I"],
  ["QRK", "X"],
  ["QRKXU", "V"],
  ["QRKXUVW", "A"],
  ["QRKXUVWAJ", "C"],
  ["QRL", "X"],
  ["QRLXU", "V"],
  ["QRLXUVW", "A"],
  ["QRLXUVWAJ", "C"],
  ["QRM", "X"],
  ["QRMXU", "V"],
  ["QRMXUVW", "A"],
  ["QRMXUVWAJ", "C"],
  ["QRN", "I"],
  ["QRNIM", "G"],
  ["QRNIMGH", "P"],
  ["QRNIMGHPL", "S"],
  ["QRNIMGHPLSV", "D"],
  ["QRO", "I"],
  ["QROIM", "G"],
  ["QROIMGH", "P"],
  ["QROIMGHPL", "S"],
  ["QROIMGHPLSV", "D"],
  ["QRP", "X"],
  ["QRPXU", "V"],
  ["QRPXUVW", "A"],
  ["QRPXUVWAJ", "C"],
  ["QRS", "U"],
  ["QRSUX", "F"],
  ["QRSUXFN", "I"],
  ["QRT", "U"],
  ["QRTUX", "F"],
  ["QRTUXFN", "I"],
  ["QRU", "I"],
  ["QRUIM", "G"],
  ["QRUIMGH", "P"],
  ["QRUIMGHPL", "S"],
  ["QRUIMGHPLSV", "D"],
  ["QRV", "I"],
  ["QRVIM", "F"],
  ["QRVIMFC", "U"],
  ["QRW", "I"],
  ["QRWIM", "F"],
  ["QRWIMFC", "U"],
  ["QRX", "I"],
  ["QRXIM", "G"],
  ["QRXIMGH", "P"],
  ["QRXIMGHPL", "V"],
  ["QRXIMGHPLVS", "A"],
  ["R", "PG"],
  ["S", "UF"],
  ["SA", "V"],
  ["SAVP", "D"],
  ["SAVPDK", "F"],
  ["SAVPDKFE", "C"],
  ["SAVPDKFECI", "X"],
  ["SB", "U"],
  ["SBUT", "R"],
  ["SBUTRX", "I"],
  ["SBUTRXIM", "G"],
  ["SBUTRXIMGH", "D"],
  ["SC", "V"],
  ["SCVP", "A"],
  ["SCVPAJ", "G"],
  ["SCVPAJGD", "I"],
  ["SCVPAJGDIH", "R"],
  ["SCVPAJGDIHRM", "U"],
  ["SD", "P"],
  ["SDPV", "G"],
  ["SDPVGL", "I"],
  ["SDPVGLIH", "F"],
  ["SDPVGLIHFC", "U"],
  ["SE", "U"],
  ["SEUT", "X"],
  ["SEUTXR", "C"],
  ["SEUTXRCO", "A"],
  ["SEUTXRCOAB", "D"],
  ["SF", "U"],
  ["SFUT", "X"],
  ["SFUTXR", "C"],
  ["SFUTXRCO", "A"],
  ["SFUTXRCOAB", "D"],
  ["SG", "P"],
  ["SGPV", "R"],
  ["SGPVRQ", "I"],
  ["SGPVRQIM", "F"],
  ["SGPVRQIMFC", "D"],
  ["SH", "U"],
  ["SHUT", "X"],
  ["SHUTXR", "C"],
  ["SHUTXRCO", "A"],
  ["SHUTXRCOAB", "D"],
  ["SI", "P"],
  ["SIPV", "G"],
  ["SIPVGL", "A"],
  ["SIPVGLAD", "C"],
  ["SIPVGLADCB", "X"],
  ["SIPVGLADCBXO", "U"],
  ["SJ", "U"],
  ["SJUT", "X"],
  ["SJUTXR", "C"],
  ["SJUTXRCO", "A"],
  ["SJUTXRCOAB", "D"],
  ["SK", "P"],
  ["SKPV", "G"],
  ["SKPVGL", "I"],
  ["SKPVGLIH", "F"],
  ["SKPVGLIHFC", "U"],
  ["SL", "U"],
  ["SLUT", "X"],
  ["SLUTXR", "C"],
  ["SLUTXRCO", "A"],
  ["SLUTXRCOAB", "D"],
  ["SM", "D"],
  ["SMDK", "A"],
  ["SMDKAG", "C"],
  ["SMDKAGCB", "X"],
  ["SMDKAGCBXO", "U"],
  ["SN", "U"],
  ["SNUT", "X"],
  ["SNUTXR", "C"],
  ["SNUTXRCO", "A"],
  ["SNUTXRCOAB", "D"],
  ["SO", "D"],
  ["SODK", "G"],
  ["SODKGA", "I"],
  ["SODKGAIH", "R"],
  ["SODKGAIHRM", "U"],
  ["SP", "U"],
  ["SPUT", "R"],
  ["SPUTRX", "I"],
  ["SPUTRXIM", "G"],
  ["SPUTRXIMGH", "D"],
  ["SQ", "D"],
  ["SQDK", "A"],
  ["SQDKAG", "C"],
  ["SQDKAGCB", "X"],
  ["SQDKAGCBXO", "U"],
  ["SR", "P"],
  ["SRPV", "G"],
  ["SRPVGL", "I"],
  ["SRPVGLIH", "F"],
  ["SRPVGLIHFC", "U"],
  ["ST", "P"],
  ["STPV", "R"],
  ["STPVRQ", "I"],
  ["STPVRQIM", "F"],
  ["STPVRQIMFC", "D"],
  ["SU", "P"],
  ["SUPV", "R"],
  ["SUPVRQ", "I"],
  ["SUPVRQIM", "F"],
  ["SUPVRQIMFC", "D"],
  ["SV", "U"],
  ["SVUT", "X"],
  ["SVUTXR", "C"],
  ["SVUTXRCO", "A"],
  ["SVUTXRCOAB", "D"],
  ["SW", "U"],
  ["SWUT", "X"],
  ["SWUTXR", "C"],
  ["SWUTXRCO", "A"],
  ["SWUTXRCOAB", "D"],
  ["SX", "V"],
  ["SXVP", "A"],
  ["SXVPAJ", "C"],
  ["SXVPAJCB", "F"],
  ["SXVPAJCBFI", "U"],
  ["T", "U"],
  ["TUA", "R"],
  ["TUARX", "P"],
  ["TUARXPQ", "G"],
  ["TUARXPQGL", "I"],
  ["TUB", "R"],
  ["TUBRX", "P"],
  ["TUBRXPQ", "G"],
  ["TUBRXPQGL", "I"],
  ["TUC", "F"],
  ["TUCFN", "D"],
  ["TUCFNDE", "S"],
  ["TUCFNDESK", "V"],
  ["TUCFNDESKVP", "A"],
  ["TUD", "R"],
  ["TUDRX", "P"],
  ["TUDRXPQ", "G"],
  ["TUDRXPQGL", "I"],
  ["TUE", "R"],
  ["TUERX", "P"],
  ["TUERXPQ", "G"],
  ["TUERXPQGL", "I"],
  ["TUF", "R"],
  ["TUFRX", "P"],
  ["TUFRXPQ", "G"],
  ["TUFRXPQGL", "I"],
  ["TUG", "X"],
  ["TUGXR", "C"],
  ["TUGXRCO", "F"],
  ["TUH", "X"],
  ["TUHXR", "C"],
  ["TUHXRCO", "F"],
  ["TUI", "F"],
  ["TUIFN", "D"],
  ["TUIFNDE", "S"],
  ["TUIFNDESK", "V"],
  ["TUIFNDESKVP", "A"],
  ["TUJ", "R"],
  ["TUJRX", "P"],
  ["TUJRXPQ", "G"],
  ["TUJRXPQGL", "I"],
  ["TUK", "R"],
  ["TUKRX", "P"],
  ["TUKRXPQ", "G"],
  ["TUKRXPQGL", "I"],
  ["TUL", "X"],
  ["TULXR", "C"],
  ["TULXRCO", "F"],
  ["TUM", "F"],
  ["TUMFN", "D"],
  ["TUMFNDE", "S"],
  ["TUMFNDESK", "V"],
  ["TUMFNDESKVP", "A"],
  ["TUN", "R"],
  ["TUNRX", "P"],
  ["TUNRXPQ", "G"],
  ["TUNRXPQGL", "I"],
  ["TUO", "F"],
  ["TUOFN", "D"],
  ["TUOFNDE", "S"],
  ["TUOFNDESK", "V"],
  ["TUOFNDESKVP", "A"],
  ["TUP", "F"],
  ["TUPFN", "C"],
  ["TUPFNCI", "X"],
  ["TUQ", "F"],
  ["TUQFN", "C"],
  ["TUQFNCI", "X"],
  ["TUR", "F"],
  ["TURFN", "D"],
  ["TURFNDE", "S"],
  ["TURFNDESK", "P"],
  ["TURFNDESKPV", "G"],
  ["TUS", "R"],
  ["TUSRX", "P"],
  ["TUSRXPQ", "G"],
  ["TUSRXPQGL", "I"],
  ["TUV", "X"],
  ["TUVXR", "C"],
  ["TUVXRCO", "F"],
  ["TUW", "X"],
  ["TUWXR", "C"],
  ["TUWXRCO", "F"],
  ["TUX", "F"],
  ["TUXFN", "D"],
  ["TUXFNDE", "S"],
  ["TUXFNDESK", "V"],
  ["TUXFNDESKVP", "A"],
  ["U", "SD"],
  ["V", "XC"],
  ["W", "X"],
  ["WXA", "R"],
  ["WXARU", "P"],
  ["WXARUPQ", "G"],
  ["WXARUPQGL", "I"],
  ["WXB", "R"],
  ["WXBRU", "P"],
  ["WXBRUPQ", "G"],
  ["WXBRUPQGL", "I"],
  ["WXC", "R"],
  ["WXCRU", "P"],
  ["WXCRUPQ", "G"],
  ["WXCRUPQGL", "I"],
  ["WXD", "R"],
  ["WXDRU", "P"],
  ["WXDRUPQ", "G"],
  ["WXDRUPQGL", "I"],
  ["WXE", "R"],
  ["WXERU", "P"],
  ["WXERUPQ", "G"],
  ["WXERUPQGL", "I"],
  ["WXF", "C"],
  ["WXFCO", "A"],
  ["WXFCOAB", "V"],
  ["WXFCOABVJ", "S"],
  ["WXFCOABVJSP", "D"],
  ["WXG", "U"],
  ["WXGUR", "F"],
  ["WXGURFN", "C"],
  ["WXH", "U"],
  ["WXHUR", "F"],
  ["WXHURFN", "C"],
  ["WXI", "C"],
  ["WXICO", "A"],
  ["WXICOAB", "V"],
  ["WXICOABVJ", "S"],
  ["WXICOABVJSP", "D"],
  ["WXJ", "R"],
  ["WXJRU", "P"],
  ["WXJRUPQ", "G"],
  ["WXJRUPQGL", "I"],
  ["WXK", "R"],
  ["WXKRU", "P"],
  ["WXKRUPQ", "G"],
  ["WXKRUPQGL", "I"],
  ["WXL", "U"],
  ["WXLUR", "F"],
  ["WXLURFN", "C"],
  ["WXM", "C"],
  ["WXMCO", "A"],
  ["WXMCOAB", "V"],
  ["WXMCOABVJ", "S"],
  ["WXMCOABVJSP", "D"],
  ["WXN", "C"],
  ["WXNCO", "A"],
  ["WXNCOAB", "V"],
  ["WXNCOABVJ", "S"],
  ["WXNCOABVJSP", "D"],
  ["WXO", "R"],
  ["WXORU", "P"],
  ["WXORUPQ", "G"],
  ["WXORUPQGL", "I"],
  ["WXP", "C"],
  ["WXPCO", "F"],
  ["WXPCOFI", "U"],
  ["WXQ", "C"],
  ["WXQCO", "F"],
  ["WXQCOFI", "U"],
  ["WXR", "C"],
  ["WXRCO", "A"],
  ["WXRCOAB", "V"],
  ["WXRCOABVJ", "P"],
  ["WXRCOABVJPS", "G"],
  ["WXS", "U"],
  ["WXSUR", "F"],
  ["WXSURFN", "C"],
  ["WXT", "U"],
  ["WXTUR", "F"],
  ["WXTURFN", "C"],
  ["WXU", "C"],
  ["WXUCO", "A"],
  ["WXUCOAB", "V"],
  ["WXUCOABVJ", "S"],
  ["WXUCOABVJSP", "D"],
  ["WXV", "R"],
  ["WXVRU", "P"],
  ["WXVRUPQ", "G"],
  ["WXVRUPQGL", "I"],
  ["X", "VA"],
  ]);

  const SOROK=["ABC","DEF","GHI","AJV","DKS","GLP","PQR","STU","VWX","IMR","FNU","COX","ADG","CFI","PSV","RUX"];
  const VILLAK=["ABCDG","ABCJV","ADGJV","CABFI","CABOX","CFIOX","DAGEF","DAGKS","DEFKS","FCIDE","FCINU","FDENU","GADHI","GADLP","GHILP","ICFGH","ICFMR","IGHMR","PGLQR","PGLSV","PQRSV","RIMPQ","RIMUX","RPQUX","SDKPV","SDKTU","SPVTU","UFNST","UFNRX","USTRX","VAJPS","VAJWX","VPSWX","XCORU","XCOVW","XRUVW"];
  const ATLOSVILLAK=["ADGBCJV","CFIABOX","DAGEFKS","FCIDENU","GADHILP","ICFGHMR","PSVGLQR","RUXIMPQ","SPVDKTU","URXFNST","VPSAJWX","XRUCOVW"];
  const JOKOZEPSOK=["B","E","H","J","K","L","M","N","O","Q","T","W"];
export function strategy(state: State<MyGameState & GameStateMixin>, botID: string): [string | undefined, string] {
  if(state.G.difficulty === "live"){
    let rEddigiek:string[] = [];
    if(state.G.firstPlayer === 0){
      rEddigiek = state.G.playerLetters.flatMap(
        (element, index) => [element, state.G.enemyLetters[index]]
      );
    }
    else if (state.G.firstPlayer === 1){
      rEddigiek = state.G.enemyLetters.flatMap(
        (element, index) => [element, state.G.playerLetters[index]]
      );
    }
    let rEddigiekString = rEddigiek.join("");
    for (let i of SOROK){ //nyerés
      if( state.G.enemyLetters.includes(i[0]) && state.G.enemyLetters.includes(i[1]) && state.G.remainingLetters.includes(i[2])){
        return [i[2], "clickCell"];
      }
      if( state.G.enemyLetters.includes(i[0]) && state.G.enemyLetters.includes(i[2]) && state.G.remainingLetters.includes(i[1])){
        return [i[1], "clickCell"];
      }
      if( state.G.enemyLetters.includes(i[2]) && state.G.enemyLetters.includes(i[1]) && state.G.remainingLetters.includes(i[0])){
        return [i[0], "clickCell"];
      }
    }

    for (let i of SOROK){ //blokkolás
      if( state.G.playerLetters.includes(i[0]) && state.G.playerLetters.includes(i[1]) && state.G.remainingLetters.includes(i[2])){
        return [i[2], "clickCell"];
      }
      if( state.G.playerLetters.includes(i[0]) && state.G.playerLetters.includes(i[2]) && state.G.remainingLetters.includes(i[1])){
        return [i[1], "clickCell"];
      }
      if( state.G.playerLetters.includes(i[2]) && state.G.playerLetters.includes(i[1]) && state.G.remainingLetters.includes(i[0])){
        return [i[0], "clickCell"];
      }
    }

    if (megadottValaszlepesek.has(rEddigiekString)){ //megadott válasz
      let valasz=megadottValaszlepesek.get(rEddigiekString) as string;
      if (valasz.length>1){
        valasz=valasz[Math.floor(Math.random()*valasz.length)];
      }
      return [valasz,"clickCell"];
    }

    for (let i of VILLAK){ //villa
      if( state.G.remainingLetters.includes(i[0]) && state.G.playerLetters.includes(i[1]) && state.G.playerLetters.includes(i[3]) && state.G.remainingLetters.includes(i[2]) && state.G.remainingLetters.includes(i[4])){
        return [i[0], "clickCell"];
      }
      if( state.G.remainingLetters.includes(i[0]) && state.G.playerLetters.includes(i[1]) && state.G.playerLetters.includes(i[4]) && state.G.remainingLetters.includes(i[2]) && state.G.remainingLetters.includes(i[3])){
        return [i[0], "clickCell"];
      }
      if( state.G.remainingLetters.includes(i[0]) && state.G.playerLetters.includes(i[2]) && state.G.playerLetters.includes(i[3]) && state.G.remainingLetters.includes(i[1]) && state.G.remainingLetters.includes(i[4])){
        return [i[0], "clickCell"];
      }
      if( state.G.remainingLetters.includes(i[0]) && state.G.playerLetters.includes(i[2]) && state.G.playerLetters.includes(i[4]) && state.G.remainingLetters.includes(i[1]) && state.G.remainingLetters.includes(i[3])){
        return [i[0], "clickCell"];
      }
    }

    //ingyen mezők befoglalása
    //(minden vízsz/függ. sor középső eleme mindkét irányban --> 24 eset)
    for(let i of SOROK){
      if(JOKOZEPSOK.includes(i[1])){
        if(state.G.enemyLetters.includes(i[0]) && state.G.remainingLetters.includes(i[1]) && state.G.remainingLetters.includes(i[2])){
          return [i[2], "clickCell"];
        }
        if(state.G.enemyLetters.includes(i[2]) && state.G.remainingLetters.includes(i[1]) && state.G.remainingLetters.includes(i[0])){
          return [i[0], "clickCell"];
        }
      }
    }

    //tetszőleges támadás úgy, hogy az ellenfélnek ne legyen 2-ese
    for(let i of ATLOSVILLAK){
      if(!(state.G.playerLetters.includes(i[3]) && state.G.remainingLetters.includes(i[4])) && !(state.G.playerLetters.includes(i[4]) && state.G.remainingLetters.includes(i[3])) && !(state.G.playerLetters.includes(i[5]) && state.G.remainingLetters.includes(i[6])) && !(state.G.playerLetters.includes(i[6]) && state.G.remainingLetters.includes(i[5]))){
        if(state.G.enemyLetters.includes(i[1]) && state.G.remainingLetters.includes(i[2])){
          return [i[2], "clickCell"];
        }
        if(state.G.enemyLetters.includes(i[2]) && state.G.remainingLetters.includes(i[1])){
          return [i[1], "clickCell"];
        }
      }
    }

    for (let i of VILLAK){ //villablokkolás
      if( state.G.remainingLetters.includes(i[0]) && state.G.enemyLetters.includes(i[1]) && state.G.enemyLetters.includes(i[3]) && state.G.remainingLetters.includes(i[2]) && state.G.remainingLetters.includes(i[4])){
        return [i[0], "clickCell"];
      }
      if( state.G.remainingLetters.includes(i[0]) && state.G.enemyLetters.includes(i[1]) && state.G.enemyLetters.includes(i[4]) && state.G.remainingLetters.includes(i[2]) && state.G.remainingLetters.includes(i[3])){
        return [i[0], "clickCell"];
      }
      if( state.G.remainingLetters.includes(i[0]) && state.G.enemyLetters.includes(i[2]) && state.G.enemyLetters.includes(i[3]) && state.G.remainingLetters.includes(i[1]) && state.G.remainingLetters.includes(i[4])){
        return [i[0], "clickCell"];
      }
      if( state.G.remainingLetters.includes(i[0]) && state.G.enemyLetters.includes(i[2]) && state.G.enemyLetters.includes(i[4]) && state.G.remainingLetters.includes(i[1]) && state.G.remainingLetters.includes(i[3])){
        return [i[0], "clickCell"];
      }
    }

    //döntetlenre játszás (random lehetőség választása a legtöbb lehetséges játékossort elblokkolók közül)
    if(state.G.remainingLetters.length === 1){
      return [state.G.remainingLetters[0], "clickCell"];
    }
    let hanyban = new Array<number>(state.G.remainingLetters.length).fill(0);
    for(let i in SOROK){
      if(!(state.G.enemyLetters.includes(i[0])) && !(state.G.enemyLetters.includes(i[1])) && !(state.G.enemyLetters.includes(i[2]))){
        for (let j=0; j<state.G.remainingLetters.length; j++){
					if ((state.G.remainingLetters[j] === i[0]) || (state.G.remainingLetters[j] === i[1]) || (state.G.remainingLetters[j] === i[2])){
						hanyban[j]++;
					}
				}
      }
    }
    let max = Math.max(...hanyban);
    let indexes:number[] = [];

    for (let index = 0; index < hanyban.length; index++) {
      if (hanyban[index] === max) {
        indexes.push(index);
      }
    }

    return [state.G.remainingLetters[indexes[Math.floor(Math.random()*indexes.length)]], "clickCell"];
  }
  else{
    return [undefined, "clickCell"];
  } 
}
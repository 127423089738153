import React from 'react';
import './App.css';
import { Main } from './client/components/Main';

function App() {

  return (
    <Main />
  )
}

export default App;
